import { Link } from 'components/Link';
import { textSmall, textSmallLink } from 'styles/typography';
import { footerStyles } from './Footer.styles';

export const currentYear: number = new Date().getFullYear();

export const Footer: React.FC = () => {
  return (
    <footer className={footerStyles}>
      <span className={textSmall}>&copy; {currentYear} Clarifai, Inc.</span>
      <Link className={textSmallLink} rel="noreferrer" target="_blank" href="https://www.clarifai.com/company/terms">
        Community Terms of Service
      </Link>
      <Link className={textSmallLink} rel="noreferrer" target="_blank" href="https://www.clarifai.com/company/privacy-policy">
        Privacy Policy
      </Link>
    </footer>
  );
};
