import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

export interface ListMarketingBannersResponse {
  total: number;
  results: HUBSPOT.API.ExploreBannerResult[];
}

const portalId = '4505120';
const tableId = '5416770';

export const listMarketingBannersTE = <E = CF.API.ApiError>(
  _params: Record<string, unknown> = {},
  onRejected?: () => E,
): TaskEither<E, ListMarketingBannersResponse> =>
  fetchTE<ListMarketingBannersResponse, E>(
    {
      path: `/cms/v3/hubdb/tables/${tableId}/rows?portalId=${portalId}`,
      apiHost: 'https://api.hubapi.com',
      method: 'GET',
      useRequestPrefixHeader: false,
    },
    onRejected,
  );
