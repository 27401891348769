import { css } from '@linaria/core';
import { breakpoints, flex } from 'styles/utils';
export const headerWrapper = `${"odules__Featured__components__Header__Header_styles_ts-headerWrapper-h1md14bo"}
  ${flex.init}
  ${flex.direction.column}
  ${flex.alignItems.center}
  ${flex.justifyContent.center}
`;
const graphImage = "odules__Featured__components__Header__Header_styles_ts-graphImage-gmy9jyc";
export const graphImageLeft = `${"odules__Featured__components__Header__Header_styles_ts-graphImageLeft-g3l7bri"}
  ${graphImage}
`;
export const graphImageRight = `${"odules__Featured__components__Header__Header_styles_ts-graphImageRight-g1egzkk5"}
${graphImage}
`;

require("./Header.styles.linaria.module.css!=!../../../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./Header.styles.ts");