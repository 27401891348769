import { FC } from 'react';
import { useRouter, NextRouter } from 'next/router';
import { observer } from 'mobx-react-lite';
import { flex } from 'styles/utils';
import { cx, LinariaClassName } from '@linaria/core';
import { Link } from 'components/Link';
import { useFeatureFlagsLD } from 'modules/FeatureFlagsLD/FeatureFlagsProviderLD';
import { wrapper, tab, tabActive } from './styles';

export type EntityTab = {
  url: string;
  text: string;
  testid?: string;
  isActiveClbck?: (router: NextRouter) => boolean;
};

export interface EntityTabListProps {
  makeTabList?: typeof makeDefaultTabList;
  className?: LinariaClassName;
}

const datasetText = 'Datasets';

const makeDefaultTabList = (): EntityTab[] => [
  {
    url: '/explore',
    text: 'Featured',
    testid: 'tabsFeaturedExploreLink',
  },
  {
    url: '/explore/apps',
    text: 'Apps / Templates',
    testid: 'tabsFeaturedAppsLink',
    isActiveClbck: (router) => {
      return router.pathname.includes('/explore/apps');
    },
  },
  {
    url: '/explore/datasets',
    text: datasetText,
    testid: 'tabsFeaturedDatasetsLink',
  },
  {
    url: '/explore/models',
    text: 'Models',
    testid: 'tabsFeaturedModelsLink',
  },
  {
    url: '/explore/workflows',
    text: 'Workflows',
    testid: 'tabsFeaturedWorkflowsLink',
  },
  {
    url: '/explore/modules',
    text: 'Modules',
    testid: 'tabsFeaturedModulesLink',
  },
];

export const NavTabs: FC<EntityTabListProps> = observer(({ makeTabList = makeDefaultTabList, className, ...rest }) => {
  const { 'enable-dataset-listing': enableDatasetListing } = useFeatureFlagsLD();
  const router = useRouter();
  const tabList = makeTabList().filter((tabItem) => (tabItem.text === datasetText ? enableDatasetListing : true));

  const classNames = [wrapper];
  if (className) {
    classNames.push(className);
  }

  return (
    <nav className={cx(...classNames)} {...rest} style={{ overflow: 'auto' }}>
      <ul className={cx(flex.init)} style={{ flex: 1 }}>
        {tabList.map((tabItem) => {
          const isTabActive = tabItem.isActiveClbck ? tabItem.isActiveClbck(router) : router.asPath.split('?')[0] === tabItem.url;
          const viewMode = router.query.viewMode;
          const href = `${tabItem.url}${viewMode ? `?viewMode=${encodeURIComponent(viewMode as string)}` : ''}`;
          return (
            <li
              key={tabItem.url}
              className={cx(tab, isTabActive && tabActive)}
              data-active={isTabActive}
              style={{ justifyContent: 'center', display: 'flex', alignItems: 'center', fontSize: 14 }}
            >
              <Link href={href} data-testid={tabItem.testid || ''}>
                {tabItem.text}
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
});
