import { deleteWorkflowTE, starWorkflowTE, unstarWorkflowTE } from 'api/workflows';
import cogoToast from 'cogo-toast';
import { either } from 'fp-ts';
import { types as t, destroy, Instance } from 'mobx-state-tree';
import { errorToReactLeft, pipe } from 'utils/fp';
import { withRunInAction } from 'utils/withRunInAction';
import { safelyRemoveFromParentIfUsedInListable } from 'modules/Listings/store/helpers';

export const workflowMSTTypeOnly = t.model('CFWorkflowMST', {
  id: t.string,
  app_id: t.string,
  created_at: t.string,
  description: t.maybe(t.string),
  check_consents: t.maybe(t.array(t.string)),
  nodes: t.optional(t.frozen<CF.API.Workflows.Workflow['nodes']>(), []),
  metadata: t.frozen<CF.API.Metadata>(),
  visibility: t.model({
    gettable: t.number,
  }),
  user_id: t.string,
  modified_at: t.string,
  notes: t.maybe(t.string),
  version: t.frozen<CF.API.Workflows.WorkflowVersion>(),
  is_starred: t.maybe(t.boolean), // this key can be used to decide whether or not to display the stars
  star_count: t.optional(t.number, 0), // making this optional because we need the star count to fallback to 0 at all times
  image: t.maybe(
    t.model({
      url: t.optional(t.string, ''),
      base64: t.maybe(t.string),
      hosted: t.maybe(
        t.model({
          crossorigin: t.maybe(t.string),
          sizes: t.optional(t.array(t.string), []),
          prefix: t.optional(t.string, ''),
          suffix: t.optional(t.string, ''),
        }),
      ),
    }),
  ),
});

export const workflowMST = t
  .compose(withRunInAction(), workflowMSTTypeOnly)
  .named('workflowMSTWithListActions')
  .actions((self) => ({
    star: async () => {
      self.is_starred = true;
      self.star_count += 1;
      // Call the api
      const api = await starWorkflowTE({ userId: self.user_id, appId: self.app_id, body: { workflow_stars: [{ workflow_id: self.id }] } })();
      self.runInAction(() => {
        pipe(
          api,
          either.fold(
            () => {
              cogoToast.error('Could not star the workflow');
              self.is_starred = false;
              self.star_count -= 1;
            },
            () => null,
          ),
        );
      });
    },

    unstar: async () => {
      self.is_starred = false;
      self.star_count -= 1;
      const api = await unstarWorkflowTE({ userId: self.user_id, appId: self.app_id, body: { workflow_ids: [self.id] } })();
      pipe(
        api,
        either.fold(
          () => {
            cogoToast.error('Could not unstar the workflow');
            self.runInAction(() => {
              self.is_starred = true;
              self.star_count += 1;
            });
          },
          () => null,
        ),
      );
    },
  }))
  .actions((self) => ({
    delete: async ({ closeDeleteModal }: { closeDeleteModal: () => void }) => {
      // call the api
      const api = await deleteWorkflowTE({ userId: self.user_id, appId: self.app_id, workflowId: self.id }, errorToReactLeft)();
      pipe(
        api,
        either.fold(
          ({ props }) => {
            cogoToast.error(props?.reason || 'Could not delete the Workflow', { heading: props?.title || 'Error' });
            closeDeleteModal();
          },
          () => {
            cogoToast.success(`Workflow ${self.id} deleted!`);
            self.runInAction(() => {
              safelyRemoveFromParentIfUsedInListable(self as typeof self & { delete: () => Promise<void> });
              destroy(self);
            });
            closeDeleteModal();
          },
        ),
      );
    },
  }));

export const workflowListMST = t.array(workflowMST);

export type TWorkflowMST = Instance<typeof workflowMST>;
export type TWorkflowMSTypeOnly = Instance<typeof workflowMSTTypeOnly>;
