import { BreadcrumbJsonLd } from 'next-seo';
import { Footer } from 'components/Footer';
import { cx } from '@linaria/core';
import { withApplicationLayout } from 'hocs/withApplicationLayout';
import { NavTabs } from 'modules/Featured/components/NavTabs/NavTabs';
import { Header } from 'modules/Featured/components/Header/Header';
import { Featured, featuredApiEffects, SessionTokenProp } from 'modules/Featured/components/FeaturedItems/FeaturedItems';
import { wrapper, extraMargin, navbarWrapper } from 'modules/Featured/styles';
import { makeAppQueryClient } from 'utils/apiCache/queryClient';
import { dehydrate } from 'react-query';
import { prefetchQueryTE, queryKeys } from 'utils/react-query';
import { createCookieServerStorage } from 'utils/cookieStorage';
import { buildCommunityBreadcrumbs } from 'utils/breadcrumbs';
import { getDefautServerProps } from 'server/getDefaultServerProps';
import { getTracer } from 'utils/server/tracing';
import { GetServerSideProps } from 'next';

const FeaturedPage: React.FC<{ host: string }> = ({ host }) => {
  return (
    <>
      <BreadcrumbJsonLd itemListElements={buildCommunityBreadcrumbs({ host, page: 'featured' })} />
      <div className={cx('bound-to-max', extraMargin)}>
        <div className={wrapper}>
          <div className={navbarWrapper}>
            <NavTabs data-testid="navTabsForList" />
          </div>
          <Header />
        </div>
        <Featured />
      </div>
      <Footer />
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  return getTracer().startActiveSpan('ExplorePage/getServerSideProps', async (span) => {
    span.setAttributes({ path: ctx.resolvedUrl });
    const { req, res, params } = ctx;

    const host = req ? req.headers.host : 'clarifai.com';
    const sessionToken = createCookieServerStorage(req, res).get('session_token');
    const defautServerProps = await getDefautServerProps({ params, req, res });

    let reqParams: undefined | SessionTokenProp;
    if (sessionToken) {
      // Let us send the session token via request params
      reqParams = { sessionToken };
    }

    const queryClient = makeAppQueryClient();
    await Promise.all([
      prefetchQueryTE(queryClient, [queryKeys.Models, { featured: true }], featuredApiEffects.listFeaturedModelsTE(reqParams)),
      prefetchQueryTE(queryClient, [queryKeys.Workflows, { featured: true }], featuredApiEffects.listFeaturedWorkflowsTE(reqParams)),
      prefetchQueryTE(queryClient, [queryKeys.Modules, { featured: true }], featuredApiEffects.listFeaturedModulesTE(reqParams)),
    ]);

    const result = {
      props: {
        ...defautServerProps,
        host,
        dehydratedState: dehydrate(queryClient),
        trackEventProps: {
          eventName: 'Community Explore Page Viewed',
        },
        seoProps: {
          title: 'Explore The World’s AI Community | Clarifai',
          description:
            "Explore Clarifai's free community for AI resources. Use our state-of-the-art AI models, workflows and more to add AI into your own applications.",
        },
        traceProps: {
          spanId: span.spanContext().spanId,
          traceId: span.spanContext().traceId,
        },
      },
    };
    span.end();
    return result;
  });
};

// eslint-disable-next-line import/no-default-export
export default withApplicationLayout<{ host: string }>()(FeaturedPage);
