import { deleteModelTE } from 'api/models/deleteModel';
import { starModelTE } from 'api/models/starModel';
import { unstarModelTE } from 'api/models/unstarModel';
import cogoToast from 'cogo-toast';
import { either } from 'fp-ts';
import { types as t, Instance, destroy } from 'mobx-state-tree';
import { errorToReactLeft, pipe } from 'utils/fp';
import { withRunInAction } from 'utils/withRunInAction';
import { safelyRemoveFromParentIfUsedInListable } from 'modules/Listings/store/helpers';

export const modelMSTTypeOnly = t
  .model({
    id: t.string,
    name: t.string,
    created_at: t.string,
    check_consents: t.maybe(t.array(t.string)),
    modified_at: t.maybe(t.string),
    description: t.maybe(t.string),
    app_id: t.string,
    output_info: t.maybe(
      t.model({
        message: t.string,
        type: t.maybe(t.string),
        type_ext: t.maybe(t.string),
        fields_map: t.frozen<CF.API.Models.Model['output_info']['fields_map']>(),
      }),
    ),
    model_version: t.maybe(
      t.model({
        id: t.string,
        created_at: t.string,
        status: t.model({
          code: t.number,
          description: t.string,
        }),
        visibility: t.model({
          gettable: t.number,
        }),
        app_id: t.string,
        user_id: t.string,
        metadata: t.frozen(),
      }),
    ),
    user_id: t.string,
    input_info: t.frozen(),
    train_info: t.frozen(),
    model_type_id: t.string,
    visibility: t.model({
      gettable: t.number,
    }),
    metadata: t.frozen(),
    presets: t.frozen(),
    is_starred: t.maybe(t.boolean), // this key can be used to decide whether or not to display the stars
    star_count: t.optional(t.number, 0), // making this optional because we need the star count to fallback to 0 at all times
    workflow_recommended: t.maybe(t.boolean),
    image: t.maybe(
      t.model({
        url: t.optional(t.string, ''),
        base64: t.maybe(t.string),
        hosted: t.maybe(
          t.model({
            crossorigin: t.maybe(t.string),
            sizes: t.optional(t.array(t.string), []),
            prefix: t.optional(t.string, ''),
            suffix: t.optional(t.string, ''),
          }),
        ),
      }),
    ),
  })
  .named('CFModelMSTType');

export const modelMST = t
  .compose(withRunInAction(), modelMSTTypeOnly)
  .actions((self) => ({
    star: async (onFinish?: () => void) => {
      self.is_starred = true;
      self.star_count += 1;
      // Call the api
      const api = await starModelTE({ userId: self.user_id, appId: self.app_id, body: { model_stars: [{ model_id: self.id }] } })();
      self.runInAction(() => {
        pipe(
          api,
          either.fold(
            () => {
              cogoToast.error('Could not star the model');
              self.is_starred = false;
              self.star_count -= 1;
            },
            () => null,
          ),
          () => onFinish && onFinish(),
        );
      });
    },
    unstar: async (onFinish?: () => void) => {
      self.is_starred = false;
      self.star_count -= 1;
      const api = await unstarModelTE({ userId: self.user_id, appId: self.app_id, body: { model_ids: [self.id] } })();
      pipe(
        api,
        either.fold(
          () => {
            cogoToast.error('Could not unstar the model');
            self.runInAction(() => {
              self.is_starred = true;
              self.star_count += 1;
            });
          },
          () => null,
        ),
        () => onFinish && onFinish(),
      );
    },
  }))
  .actions((self) => ({
    delete: async ({ closeDeleteModal }: { closeDeleteModal: () => void }) => {
      pipe(
        await deleteModelTE({ modelId: self.id, appId: self.app_id, userId: self.user_id }, errorToReactLeft)(),
        either.fold(
          ({ props }) => {
            cogoToast.error(props?.reason || 'Could not delete the Model', { heading: props?.title || 'Error' });
            closeDeleteModal();
          },
          () => {
            cogoToast.success(`Model ${self.name} deleted!`);
            self.runInAction(() => {
              safelyRemoveFromParentIfUsedInListable(self as typeof self & { delete: () => Promise<void> });
              destroy(self);
            });
            closeDeleteModal();
          },
        ),
      );
    },
  }));

export const modelsMST = t.array(modelMST);

export type ModelMSTInstance = Instance<typeof modelMST>;

export type TModelMST = Instance<typeof modelMST>;
export type TModelMSTypeOnly = Instance<typeof modelMSTTypeOnly>;
