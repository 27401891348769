import { Heading } from 'modules/Featured/components/Heading/Heading';
import { Search } from 'modules/Featured/components/Search/Search';
import { headerWrapper, graphImageLeft, graphImageRight } from './Header.styles';

export const Header: React.FC = (): JSX.Element => (
  <div className={headerWrapper}>
    <Heading />
    <Search />
    <img src="/graph-image-left.svg" alt="graph-img-left" height="100%" width={300} className={graphImageLeft} />
    <img src="/graph-image-right.svg" alt="graph-img-right" height="100%" width={300} className={graphImageRight} />
  </div>
);
