/* eslint-disable @typescript-eslint/no-explicit-any */
import { taskEither, either } from 'fp-ts';
import { TaskEither } from 'fp-ts/lib/TaskEither';
import { pipe } from 'fp-ts/lib/function';
import { FC } from 'react';
import { prop } from 'rambda';
import { ReactLeft, reactLeftToJSX, UI_STATES } from 'utils/uiStates/uiStates';
import { queryKeys, useQueryTE } from 'utils/react-query';
import { listModelsTE } from 'api/models/listModels';
import { listWorkflowsTE } from 'api/workflows';
import { listModulesTE } from 'api/modules/listModules';
import { errorToReactLeft } from 'utils/fp';
import { modelsMST } from 'modules/Models/listingStore/mst-types';
import { makeModelsUrl } from 'modules/Models/listingStore/createModelsList';
import { List } from 'components/List';
import { getIsEntityOwner } from 'modules/Auth/hooks';
import { workflowListMST } from 'modules/Workflows/listingStore/mst-types';
import { makeWorkflowUrl } from 'modules/Workflows/listingStore/createWorkflowsList';
import { moduleListMST } from 'modules/Modules/listingStore/mst-types';
import { makeModuleUrl } from 'modules/Modules/listingStore/createModulesList';
import { useRouter } from 'next/router';
import { getExplorePageNameByPath } from 'utils/urls/pageName';
import { minusMargin } from 'components/List/List.styled';
import { SectionWithTitle } from '../SectionWithTitle/SectionWithTitle';
import { KnowledgeCenter } from '../KnowledgeCenter/KnowledgeCenter';
import { wrapper } from './FeaturedItems.styled';
export type SessionTokenProp = {
  sessionToken: string;
};
export const featuredApiEffects = {
  listFeaturedModelsTE: (props?: SessionTokenProp): TaskEither<ReactLeft, CF.API.Models.Model[]> => {
    return pipe(listModelsTE({
      params: 'featured_only=true&additional_fields=stars&additional_fields=presets',
      sessionToken: props && prop('sessionToken')(props)
    }, errorToReactLeft), taskEither.map(resp => resp.models));
  },
  listFeaturedWorkflowsTE: (props?: SessionTokenProp): TaskEither<ReactLeft, CF.API.Workflows.Workflow[]> => {
    return pipe(listWorkflowsTE({
      params: 'featured_only=true&additional_fields=stars',
      sessionToken: props && prop('sessionToken')(props)
    }, errorToReactLeft), taskEither.map(resp => resp.workflows));
  },
  listFeaturedModulesTE: (props?: SessionTokenProp): TaskEither<ReactLeft, CF.API.Modules.Module[]> => {
    return pipe(listModulesTE({
      params: 'featured_only=true&additional_fields=stars',
      sessionToken: props && prop('sessionToken')(props)
    }, errorToReactLeft), taskEither.map(resp => resp.modules));
  }
};
export const Featured: FC = () => {
  const {
    data: featuredWorkflows
  } = useQueryTE([queryKeys.Workflows, {
    featured: true
  }], featuredApiEffects.listFeaturedWorkflowsTE());
  const {
    data: featuredModels
  } = useQueryTE([queryKeys.Models, {
    featured: true
  }], featuredApiEffects.listFeaturedModelsTE());
  const {
    data: featuredModules
  } = useQueryTE([queryKeys.Modules, {
    featured: true
  }], featuredApiEffects.listFeaturedModulesTE());
  const router = useRouter();
  return <div className={wrapper}>
      <SectionWithTitle title="Featured Models" link={{
      title: 'Explore all models',
      url: '/explore/models'
    }}>
        {pipe(featuredModels, either.mapLeft(_ => ({
        type: UI_STATES.empty
      })), either.fold(reactLeftToJSX(), featuredModelsList => {
        const models = modelsMST.create(featuredModelsList);
        return <div className={minusMargin}>
                <List list={models} makeUrl={makeModelsUrl} pageName={getExplorePageNameByPath(router.pathname) || ''} getIsEntityOwner={getIsEntityOwner} showFeatured />
              </div>;
      }))}
      </SectionWithTitle>
      {pipe(featuredWorkflows, either.mapLeft(_ => ({
      type: UI_STATES.empty
    })), either.fold(reactLeftToJSX(), featuredWorkflowsList => {
      if (featuredWorkflowsList.length === 0) return null;
      const wfs = workflowListMST.create(featuredWorkflowsList);
      return <SectionWithTitle title="Featured Workflows" link={{
        title: 'Explore all workflows',
        url: '/explore/workflows'
      }}>
              <div className={minusMargin}>
                <List list={wfs} makeUrl={makeWorkflowUrl} pageName={getExplorePageNameByPath(router.pathname) || ''} getIsEntityOwner={getIsEntityOwner} showFeatured />
              </div>
            </SectionWithTitle>;
    }))}
      {pipe(featuredModules, either.mapLeft(_ => ({
      type: UI_STATES.empty
    })), either.fold(reactLeftToJSX(), featuredModulesList => {
      return null;
      /* TT-2689: TODO support featured_only query in API for modules then uncomment the "true"
         below */
      // eslint-disable-next-line no-constant-condition
      if (featuredModulesList.length === 0 || true) return null;
      const wfs = moduleListMST.create(featuredModulesList);
      return <SectionWithTitle title="Featured Modules" link={{
        title: 'Explore all modules',
        url: '/explore/modules'
      }}>
              <div className={minusMargin}>
                <List list={wfs} makeUrl={makeModuleUrl} pageName={getExplorePageNameByPath(router.pathname) || ''} getIsEntityOwner={getIsEntityOwner} showFeatured />
              </div>
            </SectionWithTitle>;
    }))}
      <SectionWithTitle title="Knowledge Center">
        <KnowledgeCenter />
      </SectionWithTitle>
    </div>;
};
export const testable = {
  apiEffects: featuredApiEffects,
  onError: errorToReactLeft
};