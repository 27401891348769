import { textSmallBold } from 'styles/typography';
import { Link } from 'components/Link';
import { IconPlaySimple, IconBook, IconDiscord, IconArrowRightLong } from 'components/Icons';
import { flex } from 'styles/utils';
import { cx } from '@linaria/core';
import { listMarketingBannersTE } from 'api/hubspot/listMarketingBanners';
import { ReactLeft, UI_STATES, reactLeftToJSX } from 'utils/uiStates/uiStates';
import { pipe } from 'utils/fp';
import { useQueryTE } from 'utils/react-query';
import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { either, taskEither } from 'fp-ts';
import { prop, sortBy } from 'rambda';
import { card, cardTitle, cardDescription, cardIconWrapper, ctaText, wrapper } from './KnowledgeCenter.styles';

const apiEffects = {
  listMarketingBannersTE: (): TaskEither<ReactLeft, HUBSPOT.API.ExploreBannerValues[]> => {
    return pipe(
      listMarketingBannersTE({}, () => ({ type: UI_STATES.error } as ReactLeft)),
      taskEither.map((response) => response.results.map((banner) => banner.values)),
    );
  },
};

const iconTypeMapping: Record<string, React.ReactNode> = {
  open_book: <IconWrapper icon={<IconBook size={32} color="#003ebc" />} background="#f2f6fe" />,
  video_play: <IconWrapper icon={<IconPlaySimple size={32} color="#05603a" />} background="#ecfdf3" />,
  document_edit: <IconWrapper icon={<IconDiscord size={32} color="#7289DA" />} background="#f2f6fe" />,
};

export function IconWrapper({ icon, background }: { icon: React.ReactChild; background: string }): JSX.Element {
  return (
    <div className={cardIconWrapper} style={{ background }} data-testid="banner-icon">
      {icon}
    </div>
  );
}

export const KnowledgeCenter: React.FC = () => {
  const { data } = useQueryTE(['MarketingBanners'], apiEffects.listMarketingBannersTE());

  return pipe(
    data,
    either.fold(reactLeftToJSX(), (banners) => (
      <div className={wrapper}>
        {sortBy(prop('order'))(banners).map((banner) => (
          <Link
            key={banner.title}
            href={banner.cta_link || ''}
            target="_blank"
            rel="noreferrer"
            data-testid="explore-banner"
            className={flex.flex.one}
          >
            <div className={card}>
              {iconTypeMapping[banner.icon_type[0]?.name]}
              <span className={cx(flex.init, flex.direction.column, flex.justifyContent.between)}>
                <span>
                  <h1 className={cardTitle}>{banner.title}</h1>
                  <p className={cardDescription}>{banner.description}</p>
                </span>
                <span className={ctaText}>
                  <span className={textSmallBold}>{banner.cta_text}</span>
                  <IconArrowRightLong size={18} color="#195AFF" />
                </span>
              </span>
            </div>
          </Link>
        ))}
      </div>
    )),
  );
};
