import { useState, useCallback } from 'react';
import { useRouter } from 'next/router';
import { cx } from '@linaria/core';
import { flex } from 'styles/utils';
import { Button } from 'components/deprecated/Button';
import { TagGroup, useTagState } from 'components/TagGroup';
import { IconSearch } from 'components/Icons';
import { wrapper, inputWrapper, input, button, popularSearches } from './Search.styles';

export interface SearchProps extends React.HTMLAttributes<HTMLDivElement> {}

const ENTER_KEY_STRING = 'Enter';
const options = [
  { label: 'Clarifai', value: 'clarifai' },
  { label: 'Translation', value: 'translation' },
  { label: 'Moderation', value: 'moderation' },
];

export const Search: React.FC<SearchProps> = (): JSX.Element => {
  const router = useRouter();
  const [searchKeyword, setSearchKeyword] = useState('');
  const tagStateReturn = useTagState(options, false, {});

  const handleSearch = useCallback(() => {
    navigateToModelSearch(searchKeyword);
  }, [searchKeyword]);

  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === ENTER_KEY_STRING) {
        navigateToModelSearch(searchKeyword);
      }
    },
    [searchKeyword],
  );

  const navigateToModelSearch = (value: string): void => {
    router.push(`/explore/models${value ? `?searchQuery=${value}` : ''}`);
  };

  return (
    <div className={cx(wrapper)}>
      <div className={inputWrapper}>
        <input
          type="text"
          placeholder="Search models..."
          className={input}
          onChange={({ target }) => setSearchKeyword(target.value)}
          onKeyDown={handleKeyPress}
        />
        <Button kind="translucent" onClick={handleSearch} className={button} data-testid="searchBtn">
          <IconSearch size={26} color="black" />
        </Button>
      </div>
      <div className={popularSearches}>
        <span>Popular Searches:</span>
        <div className={cx(flex.init, flex.justifyContent.center, flex.alignItems.center)}>
          <TagGroup tagState={tagStateReturn} options={options} onUpdateItem={navigateToModelSearch} />
        </div>
      </div>
    </div>
  );
};
