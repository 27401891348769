/* eslint-disable no-param-reassign */
import { either, taskEither } from 'fp-ts';
import { pipe } from 'fp-ts/lib/function';
import type { TaskEither } from 'fp-ts/lib/TaskEither';

import { ReactLeft, UI_STATES } from 'utils/uiStates/uiStates';
import { types } from 'mst-effect';
import { makeSearchable } from 'modules/Listings/store/models/Searchable';
import { createListable } from 'modules/Listings/store/models/Listable';
import { makeSortable } from 'modules/Listings/store/models/Sortable';
import { makeUISettings } from 'modules/Listings/store/models/UiSettings';
import { getIsEntityOwner } from 'modules/Auth/hooks';

import { modelSortCriteria } from 'modules/Models/listingStore/sortCriteria';

import { SupportedListingEntities } from 'modules/Listings/types';
import { listModelsTE } from 'api/models/listModels';
import { listModelsByAppTE } from 'api/models/listModelsByApp';
import { createFilterable } from 'modules/Listings/store/models/Filterable';
import { withRunInAction } from 'utils/withRunInAction';
import { QueryClient } from 'react-query';
import { errorToReactLeft } from 'utils/fp';
import { NoSearchState } from 'components/NoAppState/NoSearchState';
import { NoResourceState } from 'components/NoAppState/NoResourceState';
import { IconProcessor } from 'components/Icons';
import { NoAppResourceSubtitle } from 'components/NoAppState/NoAppResourceSubtitle';
import { baseTheme } from 'styles/utils';
import { NoAppResourceCTA } from 'components/NoAppState/NoAppResourceCTA';
import { NoStarredTemplateResource } from 'components/NoAppState/NoStarredResource';
import { modelMST } from './mst-types';

export const listModelApiEffects = {
  listModelsTE: (userId?: string, params?: string) => {
    return pipe(
      listModelsTE({ userId, params }, errorToReactLeft),
      taskEither.chain((x) => taskEither.fromEither('models' in x ? either.right(x.models) : either.left({ type: UI_STATES.invalid }))),
    );
  },
  listModelsByAppTE: (userId: string, appId: string, params?: string) => {
    return pipe(
      listModelsByAppTE({ userOrOrgId: userId, appId, params }, errorToReactLeft),
      taskEither.chain((x) =>
        taskEither.fromEither('models' in x ? either.right(x.models.filter((m) => m.app_id === appId)) : either.left({ type: UI_STATES.invalid })),
      ),
    );
  },
};

export const makeFetchModels = (userId?: string, appId?: string): ((params: string) => TaskEither<ReactLeft, CF.API.Models.Model[]>) => {
  if (userId && appId) {
    return function fetchModelsByApp(params: string): TaskEither<ReactLeft, CF.API.Models.Model[]> {
      return listModelApiEffects.listModelsByAppTE(userId, appId, params);
    };
  }

  if (userId) {
    return function fetchUsersModelsTE(params: string): TaskEither<ReactLeft, CF.API.Models.Model[]> {
      return listModelApiEffects.listModelsTE(userId, params);
    };
  }

  return function fetchModelsCommunityTE(params: string): TaskEither<ReactLeft, CF.API.Models.Model[]> {
    return listModelApiEffects.listModelsTE('', params);
  };
};

export const getEmptyState = (searchTermValue?: string, defaultParams?: string): (() => JSX.Element) => {
  return (): JSX.Element =>
    defaultParams && defaultParams.includes('&starred_only=true') ? (
      <NoStarredTemplateResource title="No starred models" subtitle="Star any model to see it here" />
    ) : searchTermValue ? (
      <NoSearchState title={`No results found for "${searchTermValue}"`} subtitle="Your search did not match any results. Please try again." />
    ) : (
      <NoResourceState
        isCtaVisible={false}
        title={(isOwner) => `No models ${isOwner ? 'created' : 'available'} yet`}
        subtitle={() => <NoAppResourceSubtitle resourceName="model" />}
        icon={<IconProcessor size={24} color={baseTheme.light.colors.blue} />}
        cta={<NoAppResourceCTA resourceName="model" />}
      />
    );
};
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const makeDependencies = (qc: QueryClient) => ({
  queryClient: qc,
  sortCriteria: modelSortCriteria,
});

export const makeModelsUrl = ({ entityId, userId, appId }: Record<string, string>): string => `/${userId}/${appId}/models/${entityId}`;

interface Props {
  queryClient: QueryClient;
  hasFilters: boolean;
  userIdFromRouter?: string;

  // TODO AVIRAL: remove isUser prop after checking if safe in all contexts
  isUser?: boolean;
  initialList?: CF.API.Models.Model[];
  isStarred?: boolean;
  makeEnv?: typeof makeDependencies;
  overrideUrlClick?: (entity: SupportedListingEntities) => void;
  defaultValues?: Record<string, string[]>;
  defaultSort?: Record<string, string>;
  initialQuery?: Record<string, string>;
  disableRouterUpdates?: boolean;
  modelsConfig?: {
    params: string;
    userId?: string;
    appId?: string;
  };
}
// inference works better than explicit type definition for MST
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const createModelsList = ({
  queryClient,
  userIdFromRouter,
  initialList,
  makeEnv = makeDependencies,
  overrideUrlClick,
  defaultValues,
  isStarred,
  initialQuery,
  defaultSort,
  disableRouterUpdates = false,
  hasFilters,
  modelsConfig,
}: Props) => {
  const externalDeps = makeEnv(queryClient);
  const ModelsList = types
    .compose(
      withRunInAction(),
      createListable({
        fetcher: makeFetchModels(modelsConfig?.userId || userIdFromRouter, modelsConfig?.appId),
        entityMstModel: modelMST.named('Model'),
        listQueryKey: ['models', { userId: modelsConfig?.userId, appId: modelsConfig?.appId }],
        initialQuery,
        disableRouterUpdates,
      }),
      makeSearchable(initialQuery, { disableRouterUpdates }),
      makeSortable({ ...defaultSort, ...initialQuery }, { disableRouterUpdates }),
      createFilterable(
        { entityType: 'model', defaultValues, disableFeature: !hasFilters, ignoreQueryFields: ['page', 'perPage', 'viewMode', 'activeToggle'] },
        initialQuery,
        { disableRouterUpdates },
      ),
      makeUISettings(initialQuery, { disableRouterUpdates }),
    )
    .volatile((self) => ({
      sortCriteria: modelSortCriteria,
      makeUrl: makeModelsUrl,
      overrideUrlClick,
      getIsEntityOwner,
      getUiStateMap: () => ({ [UI_STATES.empty]: getEmptyState(self.searchTermValue, self.defaultParams) }),
      // this is a great example of how we can add custom functionality to existing MST model with ease!
    }))
    .named('ModelsList');

  const instance = ModelsList.create(
    {
      _listItems: initialList || [],
      defaultParams: `${modelsConfig?.params ? `${modelsConfig.params}&` : ''}additional_fields=stars&additional_fields=presets${
        isStarred ? '&starred_only=true' : ''
      }`,
    },
    // DEPENDENCIES INJECTED TO INSTANCE:
    externalDeps,
  );

  return instance;
};

export const createModelsList_testable = {
  makeFetchModels,
  makeDependencies,
};
