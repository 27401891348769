/* eslint-disable @typescript-eslint/ban-ts-comment */
import type { FC } from 'react';
import { PageWrapper } from 'components/PageWrapper';

export function withApplicationLayout<T = Record<string, unknown>>(): (c: FC<T>) => FC<T> {
  return (Component: React.FC<T>): React.FC<T> =>
    (props) =>
      (
        <PageWrapper>
          <Component {...props} />
        </PageWrapper>
      );
}
