import { cx } from '@linaria/core';
import { bigH1, regularText } from 'styles/typography';
import { wrapper, H1Styles, PStyles, SupStyles } from './Heading.styles';

export const Heading: React.FC = () => {
  return (
    <div className={wrapper}>
      <h1 className={cx(bigH1, H1Styles)}>
        Explore The World&apos;s AI<sup className={SupStyles}>TM</sup> Community
      </h1>
      <h2 className={cx(regularText, PStyles)}>
        Everything you need to build your AI-powered apps. Discover, build and share AI models, workflows and app components; powered by
        Clarifai&apos;s low code, no code platform.
      </h2>
    </div>
  );
};
