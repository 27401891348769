import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const listModulesByAppTE = <E = CF.API.ApiError>(
  { userId, appId, params = '', sessionToken }: { userId: string; appId: string; params?: string; sessionToken?: string },
  onRejected?: (e: CF.API.ApiError) => E,
): TaskEither<E, CF.API.Modules.ListModulesResponse> =>
  fetchTE<CF.API.Modules.ListModulesResponse, E>(
    {
      path: `/api/_api/v2/users/${userId}/apps/${appId}/modules${params ? `?${params}` : ''}`,
      method: 'GET',
      sessionToken,
    },
    onRejected,
  );
