import { css } from '@linaria/core';
import { breakpoints, flex, theming } from 'styles/utils';
export const wrapper = `${"odules__Featured__components__Search__Search_styles_ts-wrapper-w1708v3t"}
  ${flex.init}
  ${flex.direction.column}
`;
export const inputWrapper = "odules__Featured__components__Search__Search_styles_ts-inputWrapper-i1lg5fyt";
export const input = "odules__Featured__components__Search__Search_styles_ts-input-i12j82m5";
export const button = "odules__Featured__components__Search__Search_styles_ts-button-b1pelfbg";
export const popularSearches = "odules__Featured__components__Search__Search_styles_ts-popularSearches-p1e2krhl";

require("./Search.styles.linaria.module.css!=!../../../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./Search.styles.ts");