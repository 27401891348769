import { useCallback, useEffect, useState } from 'react';

import { Tag } from 'components/Tag';
import { noop } from 'rxjs';
import { useDeepCompareEffect } from 'react-use';
import { cx } from '@linaria/core';

type BaseProps = Pick<React.HTMLAttributes<HTMLDivElement>, Exclude<keyof React.HTMLAttributes<HTMLDivElement>, 'onChange'>>;

/**
 * [state, setChecked, clear]
 */
export type TagStateReturn = [
  Record<string, boolean>, // tag state { key1: true, key2: false, ... }
  (value: string) => Record<string, boolean>, // toggle single item
  () => void, // clear state
  React.Dispatch<React.SetStateAction<Record<string, boolean>>>, // set entire state
];
export interface TagGroupProps extends BaseProps {
  options: { label: string; value: string }[];
  onChange?: (a: Record<string, boolean>) => void;
  onUpdateItem?: (currentItemKey: string, changedTagState?: Record<string, boolean>) => void;
  tagState: TagStateReturn;
  disabled?: boolean;
}

export const TagGroup: import('react').FC<TagGroupProps> = ({
  disabled,
  options,
  onChange = noop,
  tagState,
  onUpdateItem = noop,
  className,
  ...rest
}) => {
  const [state, toggleChecked] = tagState;
  const update = useCallback(
    (v: string) => {
      const nextTagState = toggleChecked(v);
      onUpdateItem(v, nextTagState);
    },
    [toggleChecked, state],
  );

  useEffect(() => {
    onChange(state);
  }, [state]);

  return (
    <div data-testid="tagGroupWrapper" className={cx(className, 'list')} {...rest}>
      {options.map((o) => (
        <Tag disabled={disabled} aria-disabled={disabled} key={o.value} id={o.value} selected={state[o.value]} onChange={disabled ? noop : update}>
          {o.label}
        </Tag>
      ))}
    </div>
  );
};

const emptyObj = {};

const clearState = (pS: Record<string, boolean>): Record<string, boolean> =>
  Object.entries(pS).reduce(
    (acc, [label]) => ({
      ...acc,
      [label]: false,
    }),
    {},
  );

export function useTagState(
  /** NEED react.useMemo */
  options: TagGroupProps['options'],
  allowMultiple: boolean,
  /** NEED react.useMemo */
  initialState: Record<string, boolean> = emptyObj,
): TagStateReturn {
  const [state, setState] = useState<Record<string, boolean>>({ ...makeTagOptions(options), ...initialState });

  useDeepCompareEffect(() => {
    setState({ ...makeTagOptions(options), ...initialState });
  }, [options, initialState]);

  const clear = useCallback(() => {
    setState(clearState);
  }, [setState]);

  const toggleChecked = useCallback(
    (value: string) => {
      // if we don't allow multiple; then on each new  click we must clear old state so that two items are not selected together
      const initial = !allowMultiple && state[value] === false ? clearState(state) : state;

      const nextValue = {
        ...initial,
        [value]: !initial[value],
      };

      setState(nextValue);

      return nextValue;
    },
    [setState, state, allowMultiple],
  );

  return [state, toggleChecked, clear, setState];
}

export function makeTagOptions(options: TagGroupProps['options']): Record<string, boolean> {
  return options.reduce((acc, { value }) => ({ ...acc, [value]: false }), {});
}
