import { either, taskEither } from 'fp-ts';
import { pipe } from 'fp-ts/lib/function';
import type { TaskEither } from 'fp-ts/lib/TaskEither';

import { ReactLeft, UI_STATES } from 'utils/uiStates/uiStates';
import { types } from 'mst-effect';
import { makeSearchable } from 'modules/Listings/store/models/Searchable';
import { createListable } from 'modules/Listings/store/models/Listable';
import { makeUISettings } from 'modules/Listings/store/models/UiSettings';
import { makeSortable } from 'modules/Listings/store/models/Sortable';
import { defineNotFilterable } from 'modules/Listings/store/models/Filterable';
import { getIsEntityOwner } from 'modules/Auth/hooks';
import { listModulesTE } from 'api/modules';
import type { QueryClient } from 'react-query';
import { errorToReactLeft } from 'utils/fp';
import { SupportedListingEntities } from 'modules/Listings/types';
import { NoSearchState } from 'components/NoAppState/NoSearchState';
import { NoResourceState } from 'components/NoAppState/NoResourceState';
import { NoAppResourceSubtitle } from 'components/NoAppState/NoAppResourceSubtitle';
import { IconModule } from 'components/Icons';
import { baseTheme } from 'styles/utils';
import { NoAppResourceCTA } from 'components/NoAppState/NoAppResourceCTA';
import { NoStarredTemplateResource } from 'components/NoAppState/NoStarredResource';
import { moduleSortCriteria } from './constants';
import { moduleMST } from './mst-types';

const makeFetchModules = (userId?: string) =>
  function fetchModulesTE(params: string): TaskEither<ReactLeft, CF.API.Modules.Module[]> {
    return pipe(
      listModulesTE({ userId, params }, errorToReactLeft),
      taskEither.chain((x) => taskEither.fromEither('modules' in x ? either.right(x.modules) : either.left({ type: UI_STATES.invalid }))),
    );
  };

export const makeModuleUrl = ({ entityId, userId, appId }: Record<string, string>): string => `/${userId}/${appId}/modules/${entityId}`;

interface Props {
  queryClient: QueryClient;
  userId?: string;
  initialList?: CF.API.Modules.Module[];
  isStarred?: boolean;
  initialQuery?: Record<string, string>;
  overrideUrlClick?: (entity: SupportedListingEntities) => void;
}

export const getEmptyState = (searchTermValue?: string, defaultParams?: string): (() => JSX.Element) => {
  return (): JSX.Element =>
    defaultParams && defaultParams.includes('&starred_only=true') ? (
      <NoStarredTemplateResource title="No starred modules" subtitle="Star any module to see it here" />
    ) : searchTermValue ? (
      <NoSearchState title={`No results found for "${searchTermValue}"`} subtitle="Your search did not match any results. Please try again." />
    ) : (
      <NoResourceState
        isCtaVisible={false}
        title={(isOwner) => `No modules ${isOwner ? 'created' : 'available'} yet`}
        subtitle={() => <NoAppResourceSubtitle resourceName="module" />}
        icon={<IconModule size={24} color={baseTheme.light.colors.blue} />}
        cta={<NoAppResourceCTA resourceName="module" />}
      />
    );
};

// inference works better than explicit type definition for MST
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const createModulesList = ({ queryClient, userId, initialList, isStarred, initialQuery, overrideUrlClick }: Props) => {
  const ModulesList = types
    .compose(
      createListable({
        fetcher: makeFetchModules(userId),
        entityMstModel: moduleMST,
        listQueryKey: ['modules', { userId }],
        initialQuery,
      }),
      makeSearchable(initialQuery),
      makeSortable(initialQuery),
      makeUISettings(initialQuery),
      defineNotFilterable(),
    )
    .volatile((self) => ({
      sortCriteria: moduleSortCriteria,
      makeUrl: makeModuleUrl,
      getIsEntityOwner,
      overrideUrlClick,
      getUiStateMap: () => ({ [UI_STATES.empty]: getEmptyState(self.searchTermValue, self.defaultParams) }),
    }))
    .named('ModulesList');

  return ModulesList.create(
    {
      _listItems: initialList || [],
      defaultParams: `additional_fields=stars${isStarred ? '&starred_only=true' : ''}`,
    },
    { sortCriteria: moduleSortCriteria, queryClient },
  );
};

export const createModulesList_testable = {
  makeFetchModules,
  getEmptyState,
};
