import { either, taskEither } from 'fp-ts';
import { pipe } from 'fp-ts/lib/function';
import type { TaskEither } from 'fp-ts/lib/TaskEither';

import { ReactLeft, UI_STATES } from 'utils/uiStates/uiStates';
import { types } from 'mst-effect';
import { makeSearchable } from 'modules/Listings/store/models/Searchable';
import { createListable } from 'modules/Listings/store/models/Listable';
import { makeSortable } from 'modules/Listings/store/models/Sortable';
import { makeUISettings } from 'modules/Listings/store/models/UiSettings';
import { getIsEntityOwner } from 'modules/Auth/hooks';
import { listWorkflowsTE } from 'api/workflows';
import type { QueryClient } from 'react-query';
import { errorToReactLeft } from 'utils/fp';
import { SupportedListingEntities } from 'modules/Listings/types';
import { NoSearchState } from 'components/NoAppState/NoSearchState';
import { NoResourceState } from 'components/NoAppState/NoResourceState';
import { NoAppResourceSubtitle } from 'components/NoAppState/NoAppResourceSubtitle';
import { IconWorkflow } from 'components/Icons';
import { baseTheme } from 'styles/utils';
import { defineNotFilterable } from 'modules/Listings/store/models/Filterable';
import { NoAppResourceCTA } from 'components/NoAppState/NoAppResourceCTA';
import responseCodes from 'server/responseCodes';
import { FetchedData } from 'modules/Listings/store/models/withEndlessPagination';
import { NoStarredTemplateResource } from 'components/NoAppState/NoStarredResource';
import { workflowSortCriteria } from './constants';
import { workflowMST } from './mst-types';

const { MIXED_SUCCESS } = responseCodes;

const makeFetchWorkflows = (userId?: string) =>
  function fetchWorkflowsTE(params: string): TaskEither<ReactLeft, FetchedData<CF.API.Workflows.Workflow>> {
    return pipe(
      listWorkflowsTE({ userId, params }, errorToReactLeft),
      taskEither.chain((x) =>
        taskEither.fromEither(
          'workflows' in x
            ? either.right({ mixedSuccessStatus: x.status?.code === MIXED_SUCCESS.code, list: x.workflows })
            : either.left({ type: UI_STATES.invalid }),
        ),
      ),
    );
  };

export const makeWorkflowUrl = ({ entityId, userId, appId }: Record<string, string>): string => `/${userId}/${appId}/workflows/${entityId}`;

interface Props {
  queryClient: QueryClient;
  userId?: string;
  initialList?: CF.API.Workflows.Workflow[];
  isStarred?: boolean;
  initialQuery?: Record<string, string>;
  overrideUrlClick?: (entity: SupportedListingEntities) => void;
}

export const getEmptyState = (searchTermValue?: string, defaultParams?: string): (() => JSX.Element) => {
  return (): JSX.Element =>
    defaultParams && defaultParams.includes('&starred_only=true') ? (
      <NoStarredTemplateResource title="No starred workflows" subtitle="Star any workflow to see it here" />
    ) : searchTermValue ? (
      <NoSearchState title={`No results found for "${searchTermValue}"`} subtitle="Your search did not match any results. Please try again." />
    ) : (
      <NoResourceState
        isCtaVisible={false}
        title={(isOwner) => `No workflows ${isOwner ? 'created' : 'available'} yet`}
        subtitle={() => <NoAppResourceSubtitle resourceName="workflow" />}
        icon={<IconWorkflow size={24} color={baseTheme.light.colors.blue} />}
        cta={<NoAppResourceCTA resourceName="workflow" />}
      />
    );
};

// inference works better than explicit type definition for MST
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const createWorkflowsList = ({ queryClient, userId, initialList, isStarred, initialQuery, overrideUrlClick }: Props) => {
  const WorkflowsList = types
    .compose(
      createListable({
        fetcher: makeFetchWorkflows(userId),
        entityMstModel: workflowMST,
        listQueryKey: ['workflows', { userId }],
        initialQuery,
      }),
      makeSearchable(initialQuery),
      makeSortable(initialQuery),
      makeUISettings(initialQuery),
      defineNotFilterable(),
    )
    .volatile((self) => ({
      sortCriteria: workflowSortCriteria,
      makeUrl: makeWorkflowUrl,
      getIsEntityOwner,
      overrideUrlClick,
      getUiStateMap: () => ({ [UI_STATES.empty]: getEmptyState(self.searchTermValue, self.defaultParams) }),
    }))
    .named('WorkflowsList');

  return WorkflowsList.create(
    {
      _listItems: initialList || [],
      defaultParams: `additional_fields=stars${isStarred ? '&starred_only=true' : ''}`,
    },
    { sortCriteria: workflowSortCriteria, queryClient },
  );
};

export const createWorkflowsList_testable = {
  makeFetchWorkflows,
  getEmptyState,
};
