import { cx } from '@linaria/core';
import { useCallback } from 'react';
import { Button } from 'components/deprecated/Button';
import { IconPlaySimple } from 'components/Icons';
import { flex } from 'styles/utils';
import { cssHelpers } from 'styles/utils/core';
import { buttonIcon, modelsStartButton } from './NoAppState.styles';
import { quickStartGuide } from './NoAppState.constants';
interface NoAppResourceCTAProps {
  resourceName: string;
}
export const NoAppResourceCTA: React.FC<NoAppResourceCTAProps> = props => {
  const {
    resourceName
  } = props;
  const openLinkButton = useCallback(() => {
    window.open(quickStartGuide, '_blank');
  }, []);
  return <div className={cx(flex.init, flex.direction.row, flex.justifyContent.center, 'fullWidth')}>
      <Button onClick={openLinkButton} data-testid="quick-start-quide-button" type="button" kind="outline-simple" className={cx(modelsStartButton, cssHelpers.margin.yt[1])}>
        <IconPlaySimple className={cx(buttonIcon)} size={15} color="#101828" />
        Introduction to {resourceName}s
      </Button>
    </div>;
};