export const modelSortCriteria = [
  {
    value: 'sort_by_star_count',
    name: 'Star Count',
  },
  {
    value: 'sort_by_modified_at',
    name: 'Last Updated',
  },
  {
    value: 'sort_by_created_at',
    name: 'Last Created',
  },
  {
    value: 'sort_by_name',
    name: 'Model ID',
  },
];
