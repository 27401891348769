import { cx } from '@linaria/core';
import { useCurrentStore } from 'modules/Listings/store/useCurrentStore';
import { textRegularLight } from 'styles/typography';
import { useCreateApplicationModal } from 'modules/Apps/hooks';
import { useRouter } from 'next/router';
import { createTaskLink, noAppSubText } from './NoAppState.styles';
import { useIsOwner } from './NoAppState.hooks';

interface NoAppResourceSubtitleProps {
  resourceName: string;
}

export const NoAppResourceSubtitle: React.FC<NoAppResourceSubtitleProps> = (props) => {
  const { resourceName } = props;
  const isOwner = useIsOwner();
  const { load } = useCurrentStore();
  const router = useRouter();
  const { canCreateApplication, openCreateApplicationModal } = useCreateApplicationModal({
    onAppCreated: (createdApp) => {
      load();
      router.push(`/${createdApp.userId}/${createdApp.appId}`);
    },
  });

  return (
    <h4 className={cx(textRegularLight, noAppSubText)}>
      {isOwner && canCreateApplication ? (
        <>
          To create a {resourceName} you must first&nbsp;
          <span className={cx(createTaskLink)} onClick={openCreateApplicationModal} onKeyDown={openCreateApplicationModal} role="link" tabIndex={0}>
            Create an App
          </span>
          &nbsp;or use an existing one. All {resourceName}s created inside of your apps will be listed here.
        </>
      ) : (
        <>This user has not created any {resourceName}s at this time.</>
      )}
    </h4>
  );
};
