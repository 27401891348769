import { cx, css } from '@linaria/core';
import { flex, theming, breakpoints } from 'styles/utils';
import { textTitle } from 'styles/typography';
export const wrapper = cx("odules__Featured__components__KnowledgeCenter__KnowledgeCenter_styles_tsx-wrapper-w1yrn4fd", flex.init);
export const card = cx("odules__Featured__components__KnowledgeCenter__KnowledgeCenter_styles_tsx-card-c1w62206", flex.flex.one, flex.init);
export const cardTitle = cx("odules__Featured__components__KnowledgeCenter__KnowledgeCenter_styles_tsx-cardTitle-c1nbwhvj", textTitle);
export const cardDescription = "odules__Featured__components__KnowledgeCenter__KnowledgeCenter_styles_tsx-cardDescription-ch3crda";
export const cardIconWrapper = cx("odules__Featured__components__KnowledgeCenter__KnowledgeCenter_styles_tsx-cardIconWrapper-ck9k54y", flex.init, flex.justifyContent.center, flex.alignItems.center);
export const ctaText = cx("odules__Featured__components__KnowledgeCenter__KnowledgeCenter_styles_tsx-ctaText-c16jpjmz", flex.init, flex.alignItems.center);

require("./KnowledgeCenter.styles.linaria.module.css!=!../../../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./KnowledgeCenter.styles.tsx");