import { deleteModuleTE, starModuleTE, unStarModuleTE } from 'api/modules';
import cogoToast from 'cogo-toast';
import { either } from 'fp-ts';
import { types as t, destroy, Instance } from 'mobx-state-tree';
import { errorToReactLeft, pipe } from 'utils/fp';
import { withRunInAction } from 'utils/withRunInAction';
import { safelyRemoveFromParentIfUsedInListable } from 'modules/Listings/store/helpers';

export const moduleMSTTypeOnly = t.model('CFModuleMST', {
  id: t.string,
  created_at: t.string,
  modified_at: t.string,
  visibility: t.model({
    gettable: t.number,
  }),
  metadata: t.frozen<CF.API.Metadata>(),
  app_id: t.string,
  user_id: t.string,
  module_version: t.frozen<CF.API.Modules.ModuleVersion>(),
  description: t.union(t.optional(t.string, ''), t.undefined),
  is_starred: t.maybe(t.boolean), // this key can be used to decide whether or not to display the stars
  star_count: t.optional(t.number, 0), // making this optional because we need the star count to fallback to 0 at all times
  image: t.maybe(
    t.model({
      url: t.optional(t.string, ''),
      base64: t.maybe(t.string),
      hosted: t.maybe(
        t.model({
          crossorigin: t.maybe(t.string),
          sizes: t.optional(t.array(t.string), []),
          prefix: t.optional(t.string, ''),
          suffix: t.optional(t.string, ''),
        }),
      ),
    }),
  ),
});

export const moduleMST = t
  .compose(withRunInAction(), moduleMSTTypeOnly)
  .named('moduleMSTWithListActions')
  .actions((self) => ({
    star: async () => {
      self.is_starred = true;
      self.star_count += 1;
      // Call the api
      const api = await starModuleTE({ userId: self.user_id, appId: self.app_id, body: { module_stars: [{ module_id: self.id }] } })();
      self.runInAction(() => {
        pipe(
          api,
          either.fold(
            () => {
              cogoToast.error('Could not star the module');
              self.is_starred = false;
              self.star_count -= 1;
            },
            () => null,
          ),
        );
      });
    },
    unstar: async () => {
      self.is_starred = false;
      self.star_count -= 1;
      const api = await unStarModuleTE({ userId: self.user_id, appId: self.app_id, body: { module_ids: [self.id] } })();
      pipe(
        api,
        either.fold(
          () => {
            cogoToast.error('Could not unstar the module');
            self.runInAction(() => {
              self.is_starred = true;
              self.star_count += 1;
            });
          },
          () => null,
        ),
      );
    },
  }))
  .actions((self) => ({
    delete: async ({ closeDeleteModal }: { closeDeleteModal: () => void }) => {
      // call the api
      const api = await deleteModuleTE({ userId: self.user_id, appId: self.app_id, moduleId: self.id }, errorToReactLeft)();
      pipe(
        api,
        either.fold(
          ({ props }) => {
            cogoToast.error(props?.reason || 'Could not delete the Module', { heading: props?.title || 'Error' });
            closeDeleteModal();
          },
          () => {
            cogoToast.success(`Module ${self.id} deleted!`);
            self.runInAction(() => {
              safelyRemoveFromParentIfUsedInListable(self as typeof self & { delete: () => Promise<void>; notes: () => '' });
              destroy(self);
            });
            closeDeleteModal();
          },
        ),
      );
    },
    notes: () => {
      return self.module_version?.notes;
    },
  }));

export const moduleListMST = t.array(moduleMST);

export type TModuleMST = Instance<typeof moduleMST>;
export type TModuleMSTypeOnly = Instance<typeof moduleMSTTypeOnly>;
